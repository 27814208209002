import { graphql } from 'gatsby';
import compose from 'lodash/fp/compose';
import filter from 'lodash/fp/filter';
import map from 'lodash/fp/map';
import PropTypes, { string } from 'prop-types';
import React from 'react';

import Container from '../../components/container';
import ContentPage from '../../components/content-page';
import CopyFourImage from '../../components/copy-four-image';
import FooterNavigation from '../../components/footer-navigation';
import GraphQLErrorList from '../../components/graphql-error-list';
import ImageWithCopy from '../../components/image-with-copy';
import LargeCopy from '../../components/large-copy';
import LargeVideo from '../../components/large-video';
import Layout from '../../containers/layout';
import OneImage from '../../components/one-image';
import PageTitle from '../../components/page-title';
import Quote from '../../components/quote';
import QuoteImage from '../../components/quote-image';
import SEO from '../../components/seo';
import SixImage from '../../components/six-image';
import Spacer from '../../components/spacer';
import ThreeArticle from '../../components/three-article';
import ThreeImage from '../../components/three-image';
import TwoColumnNotes from '../../components/two-column-notes';
import TwoImage from '../../components/two-image';
import VideoWithCopy from '../../components/video-with-copy';

export const query = graphql`
  query($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      id
      publishedAt
      previewImage {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
        }
        alt
      }
      _rawContent(resolveReferences: { maxDepth: 10 })
      title
      slug {
        current
      }
      backgroundColour
      navMenu {
        ...NavMenu
      }
    }
  }
`;

const PostTemplate = ({ data, errors }) => {
  const post = data && data.post;
  const content = compose(
    filter((item) => !item.disabled),
    map((item) => {
      let el = null;
      // eslint-disable-next-line no-underscore-dangle
      switch (item._type) {
        case 'pageTitle':
          // eslint-disable-next-line no-underscore-dangle
          el = <PageTitle key={item._key} title={item.title} />;
          break;
        case 'copyFourImage':
          el = <CopyFourImage key={item._key} {...item} />;
          break;
        case 'sixImage':
          el = <SixImage key={item._key} {...item} />;
          break;
        case 'threeArticle':
          el = <ThreeArticle key={item._key} {...item} />;
          break;
        case 'threeImage':
          el = <ThreeImage key={item._key} {...item} />;
          break;
        case 'imageWithCopy':
          el = <ImageWithCopy key={item._key} {...item} />;
          break;
        case 'twoImage':
          el = <TwoImage key={item._key} {...item} />;
          break;
        case 'quoteWithImage':
          el = <QuoteImage key={item._key} {...item} />;
          break;
        case 'quote':
          el = <Quote key={item._key} {...item} />;
          break;
        case 'twoColumnNotes':
          el = <TwoColumnNotes key={item._key} {...item} />;
          break;
        case 'oneImage':
          el = <OneImage key={item._key} {...item} />;
          break;
        case 'largeCopy':
          el = <LargeCopy key={item._key} {...item} />;
          break;
        case 'largeVideo':
          el = <LargeVideo key={item._key} {...item} />;
          break;
        case 'videoWithCopy':
          el = <VideoWithCopy key={item._key} {...item} />;
          break;
        case 'footerNavigation':
          el = <FooterNavigation key={item._key} />;
          break;
        case 'spacer':
          el = <Spacer key={item._key} />;
          break;
        default:
          el = null;
      }
      return el;
    }),
    // eslint-disable-next-line no-underscore-dangle
  )(post._rawContent || []);

  const menuItems = post.navMenu && (post.navMenu.items || []);

  return (
    <Layout menuItems={menuItems} backgroundColour={post.backgroundColour}>
      {errors && <SEO title="GraphQL Error" />}
      {post && <SEO title={post.title || 'Untitled'} backgroundColour={post.backgroundColour} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      <ContentPage>{content}</ContentPage>
    </Layout>
  );
};

PostTemplate.propTypes = {
  data: PropTypes.shape({
    post: PropTypes.shape({
      _id: PropTypes.string,
      publishedAt: string,
      slug: PropTypes.shape({
        current: PropTypes.string,
      }),
      title: PropTypes.string,
    }),
  }).isRequired,
  errors: PropTypes.shape({}),
};

PostTemplate.defaultProps = {
  errors: null,
};

export default PostTemplate;
